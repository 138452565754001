import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Form } from 'reactstrap';

import AppContext from '../../../context/Context';
import { consumerApi } from '../../../helpers/api/consumerApi';
import { showErrorsFromRequest } from '../../../helpers/api/showErrorsFromRequest';
import { PermissionType } from '../../../helpers/enums/permissionTypeEnum';
import { ProductDestination } from '../../../helpers/enums/productDestinationEnum';
import { consumerFormSchema } from '../../../helpers/formValidations/consumerFormSchema';
import { checkUserPermissions } from '../../../helpers/utils/checkUserPermissions';
import { useFormScrollIntoView } from '../../../hooks/useFormScrollIntoView';
import ProtectionSelect from '../../../screens/switchboard/components/form/ProtectionSelect';
import InputFormField from '../InputFormField';
import InputFormFieldFloatValue from '../InputFormFieldFloatValue';
import ConnectionTypeSelect from '../ProductModalForm/ProtectionRelatedFields/ConnectionTypeSelect';
import AliasInputField from './AliasInputField';
import CableTypeSelect from './CableTypeSelect';
import ConsumerTypeSelect from './ConsumerTypeSelect';

export default function ConsumerModalForm({
  defaultValues,
  isLoading,
  setIsLoading
}) {
  const { t } = useTranslation();
  const { editConsumerId, toggleConsumerModal, setFetchData, user } =
    useContext(AppContext);
  const [aliases, setAliases] = useState(defaultValues.aliases ?? []);

  const form = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(consumerFormSchema)
  });

  useFormScrollIntoView({ form });

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
      setAliases(defaultValues.aliases ?? []);
    }
  }, [defaultValues]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (editConsumerId) {
        await consumerApi.updateConsumer(editConsumerId, data);
      } else {
        await consumerApi.addConsumer(data);
      }

      toast.success(t('Consumer successfully created!'));
      setFetchData(true);
      toggleConsumerModal();
      form.reset();
      setAliases([]);
    } catch (error) {
      showErrorsFromRequest(error, t);
    }
    setIsLoading(false);
  };

  const checkPermission = ({ permissions }) => {
    return checkUserPermissions({
      isAdmin: user?.isAdmin,
      userPermissions: user?.permissions,
      permissions: permissions
    });
  };

  return (
    <FormProvider {...form}>
      <Form
        onSubmit={form.handleSubmit(onSubmit)}
        onKeyPress={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
        className="d-flex flex-column gap-1"
      >
        <InputFormField
          isDisabled={
            isLoading || !checkPermission([PermissionType.CONSUMER.MODIFY])
          }
          isRequired
          label="label"
          displayLabel="Label"
        />
        <AliasInputField
          aliases={aliases}
          setAliases={setAliases}
          checkPermission={checkPermission}
          isLoading={isLoading}
        />
        <InputFormFieldFloatValue
          isDisabled={
            isLoading || !checkPermission([PermissionType.CONSUMER.MODIFY])
          }
          isRequired
          label="power"
          displayLabel="Power"
        />
        <InputFormFieldFloatValue
          isDisabled={
            isLoading || !checkPermission([PermissionType.CONSUMER.MODIFY])
          }
          isRequired
          label="simultaneityIndicator"
          displayLabel="Simultaneity Indicator"
        />
        <ConnectionTypeSelect
          isDisabled={
            isLoading || !checkPermission([PermissionType.CONSUMER.MODIFY])
          }
        />
        <ConsumerTypeSelect
          isDisabled={
            isLoading || !checkPermission([PermissionType.CONSUMER.MODIFY])
          }
        />
        <ProtectionSelect
          isDisabled={
            isLoading || !checkPermission([PermissionType.CONSUMER.MODIFY])
          }
          destination={ProductDestination.CONSUMER}
          isRequired
        />
        <CableTypeSelect
          isDisabled={
            isLoading || !checkPermission([PermissionType.CONSUMER.MODIFY])
          }
        />
        <div>
          <Button disabled={isLoading} type="submit" color="falcon-primary">
            {isLoading ? '...' : t('Save')}
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
}
