import React from 'react';
import { useTranslation } from 'react-i18next';

import ConsumerModalForm from '../form/ConsumerModalForm';
import CardModal from './CardModal';

const ConsumerModal = ({
  isOpen,
  toggle,
  consumer,
  switchboard,
  refreshSwitchboard
}) => {
  const { t } = useTranslation();
  const isEdit =
    typeof consumer === 'object' &&
    consumer !== null &&
    Number.isInteger(consumer.id) &&
    consumer.id >= 0;

  const consumers =
    (consumer &&
      consumer.subzoneId &&
      switchboard.consumers(consumer.subzoneId)) ||
    [];

  const circuits = consumer.circuits?.filter(
    (circuit) => circuit.dimmable === false
  );

  const dimmableCircuits = consumer.circuits?.filter(
    (circuit) => circuit.dimmable === true
  );

  const defaultValues = {
    consumerId: consumer.consumerId ?? null,
    subzoneId: consumer.subzoneId,
    index: consumer.index ?? consumers.length + 1,
    label: consumer.label ?? '',
    protection: consumer.protection ?? null,
    type: consumer.type ?? null,
    power: consumer.power ?? '',
    simultaneityIndicator: consumer.simultaneityIndicator ?? '',
    extraClamps: consumer.extraClamps ?? '',
    circuits: circuits ?? [],
    dimmableCircuits: dimmableCircuits ?? [],
    cableType: consumer.cableType ?? null,
    dimmable: consumer.dimmable ?? false
  };

  return (
    <CardModal
      title={isEdit ? `${t('Consumer')} ${consumer.code}` : t('Consumer')}
      subtitle={
        isEdit
          ? `${t('Editing')} '${consumer.label}'`
          : t('Create a new consumer')
      }
      isOpen={isOpen}
      toggleModal={toggle}
    >
      <ConsumerModalForm
        isEdit={isEdit}
        defaultValues={defaultValues}
        toggle={toggle}
        switchboard={switchboard}
        refreshSwitchboard={refreshSwitchboard}
        consumer={consumer}
        consumers={consumers}
      />
    </CardModal>
  );
};

export default ConsumerModal;
