import z from 'zod';

export const switchboardConsumerFormSchema = z.object({
  consumerId: z.number().nullish(),
  index: z.number({
    required_error: 'Index is required',
    invalid_type_error: 'Index must be a number'
  }),
  label: z
    .string({ required_error: 'Label is required' })
    .trim()
    .min(1, { message: 'Label is required' }),
  protection: z.object({
    id: z.number({
      invalid_type_error: 'Protection is required',
      required_error: 'Protection is required'
    }),
    label: z.string().nullish()
  }),
  power: z.coerce
    .number({
      invalid_type_error: 'Power is required',
      required_error: 'Power is required'
    })
    .positive()
    .min(0, { message: 'Power must be greater than 0' }),
  simultaneityIndicator: z.coerce
    .number({
      invalid_type_error: 'Simultaneity indicator is required',
      required_error: 'Simultaneity indicator is required'
    })
    .max(1),
  subzoneId: z.coerce.number(),
  type: z.number({
    invalid_type_error: 'Consumer type is required',
    required_error: 'Consumer type is required'
  }),
  cableType: z.number({
    invalid_type_error: 'Cable type is required',
    required_error: 'Cable type is required'
  }),
  circuits: z
    .array(
      z.object({
        label: z.string(),
        dimmable: z.boolean()
      })
    )
    .optional(),
  dimmableCircuits: z.array(
    z.object({
      label: z.string(),
      dimmable: z.boolean()
    })
  ),
  extraClamps: z.coerce.number().optional(),
  dimmable: z.coerce.boolean()
});
